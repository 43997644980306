import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateProject } from '../../templates';
import { ProjectImage, ProjectImageDouble } from '../../components';
export const meta = {
  title: 'Property Deal Genie',
  hint: 'Property Deal Genie - UK real estate market analysis tool',
  order: 32,
  type: 'project',
  published: true,
  seoTitle: 'Property Deal Genie',
  seoDescription: 'Property Deal Genie - UK real estate market analysis tool for individual investors'
};
export const content = {
  images: {
    hero: {
      src: '/images/pdg/pdg-intro.jpg',
      alt: 'UK Real Estate Market Analysis Tool - Property Deal Genie',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    bottom: {
      src: '/images/pdg/pdg-end.jpg',
      alt: 'User Interface Design - UX Design | Property Deal Genie',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    thumbnail: {
      src: '/images/pdg/pdg-thumb.jpg',
      alt: 'Property Deal Genie',
      density: [1, 2, 4]
    }
  },
  tags: ['SaaS', 'app', 'desktop', 'analytics', 'UK', 'investment'],
  excerpt: 'Property Deal Genie is a real estate market analysis tool for individual investors in the UK. It helps to find prospective areas and evaluate available property listings.',
  quote: 'pdg',
  description: 'Property Deal Genie is a real estate market analysis tool for individual investors in the UK. It helps to find prospective areas and evaluate available property listings. The application is rich in nearly 60 metrics that are used to visualize price trends and other property and market characteristics including socio-economic data.',
  problem: 'Competitor analysis confirmed that there was a space in the UK market for an affordable real estate analysis tool that could be used by individual investors. Our first task was user research to find out what real expectations were. Which features should be included? What metrics were users interested in? How to facilitate analysis and decision-making?',
  solution: 'We quickly identified the most apparent user needs and challenges and used these as the basis for surveys and user interviews. We found and confirmed with the client that we could build the system using only residential properties due to limited investor demand for commercial properties. This approach saved substantial time, budget and resources across the board.',
  figures: [{
    value: '19.4%',
    label: 'Investors who preferred using map overlays for their analysis'
  }, {
    value: '29.2%',
    label: 'Investors whose primary goal was to evaluate a property they would consider for investment'
  }, {
    value: '33.3%',
    label: 'Investors who buy or sell 1 or 2 properties a year'
  }, {
    value: '22.6%',
    label: 'Investors for whom ROI calculation was a critical feature'
  }]
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  content,
  _frontmatter
};
const MDXLayout = TemplateProject;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHuTol0E//EABgQAQADAQAAAAAAAAAAAAAAAAEAAhEQ/9oACAEBAAEFAtxbYRI8/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BZ//EABUQAQEAAAAAAAAAAAAAAAAAACBx/9oACAEBAAY/Aqv/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhEDFBUf/aAAgBAQABPyFAC/BGBpbhvcsTL2KjfWuP/9oADAMBAAIAAwAAABBo/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxAQn//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxAKIOT/xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMUFRYXH/2gAIAQEAAT8QckVCedtuAikkvMaFjupgJ4Vt59wDriJ7cP25/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/d4495721a9cdaf85ae6ec129ddb1ac43/d2432/pdg-users.webp 314w", "/static/d4495721a9cdaf85ae6ec129ddb1ac43/41786/pdg-users.webp 628w", "/static/d4495721a9cdaf85ae6ec129ddb1ac43/d4599/pdg-users.webp 1256w", "/static/d4495721a9cdaf85ae6ec129ddb1ac43/72bc3/pdg-users.webp 1884w", "/static/d4495721a9cdaf85ae6ec129ddb1ac43/51747/pdg-users.webp 2512w", "/static/d4495721a9cdaf85ae6ec129ddb1ac43/4e71a/pdg-users.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/d4495721a9cdaf85ae6ec129ddb1ac43/7a743/pdg-users.jpg 314w", "/static/d4495721a9cdaf85ae6ec129ddb1ac43/6f105/pdg-users.jpg 628w", "/static/d4495721a9cdaf85ae6ec129ddb1ac43/10d53/pdg-users.jpg 1256w", "/static/d4495721a9cdaf85ae6ec129ddb1ac43/b2e4b/pdg-users.jpg 1884w", "/static/d4495721a9cdaf85ae6ec129ddb1ac43/fdcb3/pdg-users.jpg 2512w", "/static/d4495721a9cdaf85ae6ec129ddb1ac43/78bd4/pdg-users.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/d4495721a9cdaf85ae6ec129ddb1ac43/10d53/pdg-users.jpg",
            "alt": "User Research - UX Design | Property Deal Genie",
            "title": "User Research - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAd0RQf/EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABcQAQEBAQAAAAAAAAAAAAAAAAEAEHH/2gAIAQEAAT8hY7jBn//aAAwDAQACAAMAAAAQj/8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAIDAAMAAAAAAAAAAAAAAQARMUFRYXGB/9oACAEBAAE/EEmwHzM832jG9VCvKgcLn1n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/8c81f0c508287f900cde27701bc55a21/d2432/pdg-problems.webp 314w", "/static/8c81f0c508287f900cde27701bc55a21/41786/pdg-problems.webp 628w", "/static/8c81f0c508287f900cde27701bc55a21/d4599/pdg-problems.webp 1256w", "/static/8c81f0c508287f900cde27701bc55a21/72bc3/pdg-problems.webp 1884w", "/static/8c81f0c508287f900cde27701bc55a21/51747/pdg-problems.webp 2512w", "/static/8c81f0c508287f900cde27701bc55a21/4e71a/pdg-problems.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/8c81f0c508287f900cde27701bc55a21/7a743/pdg-problems.jpg 314w", "/static/8c81f0c508287f900cde27701bc55a21/6f105/pdg-problems.jpg 628w", "/static/8c81f0c508287f900cde27701bc55a21/10d53/pdg-problems.jpg 1256w", "/static/8c81f0c508287f900cde27701bc55a21/b2e4b/pdg-problems.jpg 1884w", "/static/8c81f0c508287f900cde27701bc55a21/fdcb3/pdg-problems.jpg 2512w", "/static/8c81f0c508287f900cde27701bc55a21/78bd4/pdg-problems.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/8c81f0c508287f900cde27701bc55a21/10d53/pdg-problems.jpg",
            "alt": "User Experience Design Problems - UX Design | Property Deal Genie",
            "title": "User Experience Design Problems - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHcRmhL/8QAFxAAAwEAAAAAAAAAAAAAAAAAABARMf/aAAgBAQABBQLSOr//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPwGH/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGxAAAgIDAQAAAAAAAAAAAAAAAREAECExQVH/2gAIAQEAAT8hATcw7TIj+Cv/2gAMAwEAAgADAAAAEDcP/8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQMBAT8Qp//EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQIBAT8QHM9v/8QAGBABAQEBAQAAAAAAAAAAAAAAAREAUSH/2gAIAQEAAT8QOphZFrzJFNJAEwrWnc+q93//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/007f36247867f82e6411882855035ecc/d2432/pdg-competition.webp 314w", "/static/007f36247867f82e6411882855035ecc/41786/pdg-competition.webp 628w", "/static/007f36247867f82e6411882855035ecc/d4599/pdg-competition.webp 1256w", "/static/007f36247867f82e6411882855035ecc/72bc3/pdg-competition.webp 1884w", "/static/007f36247867f82e6411882855035ecc/51747/pdg-competition.webp 2512w", "/static/007f36247867f82e6411882855035ecc/4e71a/pdg-competition.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/007f36247867f82e6411882855035ecc/7a743/pdg-competition.jpg 314w", "/static/007f36247867f82e6411882855035ecc/6f105/pdg-competition.jpg 628w", "/static/007f36247867f82e6411882855035ecc/10d53/pdg-competition.jpg 1256w", "/static/007f36247867f82e6411882855035ecc/b2e4b/pdg-competition.jpg 1884w", "/static/007f36247867f82e6411882855035ecc/fdcb3/pdg-competition.jpg 2512w", "/static/007f36247867f82e6411882855035ecc/78bd4/pdg-competition.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/007f36247867f82e6411882855035ecc/10d53/pdg-competition.jpg",
            "alt": "Competitor Analysis - UX Design | Property Deal Genie",
            "title": "Competitor Analysis - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABugCP/8QAFhAAAwAAAAAAAAAAAAAAAAAAAAEg/9oACAEBAAEFAhz/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABsQAAIBBQAAAAAAAAAAAAAAAAAQgQEhMUHR/9oACAEBAAE/Ia64W4Ukr//aAAwDAQACAAMAAAAQMO//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAgEBPxBX/8QAGxABAQACAwEAAAAAAAAAAAAAAREAITFBUWH/2gAIAQEAAT8QSaD9lYkEQe85B1Zue4c2p5iC10xl1n//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c80b911826971ceaa6ff5935d95eb00b/d2432/pdg-stats.webp 314w", "/static/c80b911826971ceaa6ff5935d95eb00b/41786/pdg-stats.webp 628w", "/static/c80b911826971ceaa6ff5935d95eb00b/d4599/pdg-stats.webp 1256w", "/static/c80b911826971ceaa6ff5935d95eb00b/72bc3/pdg-stats.webp 1884w", "/static/c80b911826971ceaa6ff5935d95eb00b/51747/pdg-stats.webp 2512w", "/static/c80b911826971ceaa6ff5935d95eb00b/4e71a/pdg-stats.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c80b911826971ceaa6ff5935d95eb00b/7a743/pdg-stats.jpg 314w", "/static/c80b911826971ceaa6ff5935d95eb00b/6f105/pdg-stats.jpg 628w", "/static/c80b911826971ceaa6ff5935d95eb00b/10d53/pdg-stats.jpg 1256w", "/static/c80b911826971ceaa6ff5935d95eb00b/b2e4b/pdg-stats.jpg 1884w", "/static/c80b911826971ceaa6ff5935d95eb00b/fdcb3/pdg-stats.jpg 2512w", "/static/c80b911826971ceaa6ff5935d95eb00b/78bd4/pdg-stats.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/c80b911826971ceaa6ff5935d95eb00b/10d53/pdg-stats.jpg",
            "alt": "Usability Research - UX Design | Property Deal Genie",
            "title": "Usability Research - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB1JUaWQD/xAAaEAEAAgMBAAAAAAAAAAAAAAACAQMAEBET/9oACAEBAAEFAldy0pamF6MuVn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAAAAAAAAAAAASAAEQIQIRMf/aAAgBAQAGPwIBVtFcTDlqP//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBMXH/2gAIAQEAAT8harZo2IXHesNJXP18igg7UCip/9oADAMBAAIAAwAAABCUD//EABcRAAMBAAAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8QOxf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAwADAAAAAAAAAAAAAAABABEhQVHB/9oACAEBAAE/ENCCi1awEejDRTsj0Sox5wGtewwPqiKd2EQ4n//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3d4df44e2a2c08d30521abf8a26cfb0d/d2432/pdg-mood.webp 314w", "/static/3d4df44e2a2c08d30521abf8a26cfb0d/41786/pdg-mood.webp 628w", "/static/3d4df44e2a2c08d30521abf8a26cfb0d/d4599/pdg-mood.webp 1256w", "/static/3d4df44e2a2c08d30521abf8a26cfb0d/72bc3/pdg-mood.webp 1884w", "/static/3d4df44e2a2c08d30521abf8a26cfb0d/51747/pdg-mood.webp 2512w", "/static/3d4df44e2a2c08d30521abf8a26cfb0d/4e71a/pdg-mood.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3d4df44e2a2c08d30521abf8a26cfb0d/7a743/pdg-mood.jpg 314w", "/static/3d4df44e2a2c08d30521abf8a26cfb0d/6f105/pdg-mood.jpg 628w", "/static/3d4df44e2a2c08d30521abf8a26cfb0d/10d53/pdg-mood.jpg 1256w", "/static/3d4df44e2a2c08d30521abf8a26cfb0d/b2e4b/pdg-mood.jpg 1884w", "/static/3d4df44e2a2c08d30521abf8a26cfb0d/fdcb3/pdg-mood.jpg 2512w", "/static/3d4df44e2a2c08d30521abf8a26cfb0d/78bd4/pdg-mood.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/3d4df44e2a2c08d30521abf8a26cfb0d/10d53/pdg-mood.jpg",
            "alt": "Moodboard - UX Design | Property Deal Genie",
            "title": "Moodboard - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3ZYAf//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAAIDAQAAAAAAAAAAAAAAABEhAAEQUf/aAAgBAQABPyESkWYcfTn/2gAMAwEAAgADAAAAEDAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBcYGR/9oACAEBAAE/EBeQUX0YFLEepZtMzkqtoQxK1if/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e4d2cc04b0a14f640afe1c62c4f04ec5/d2432/pdg-logo-process.webp 314w", "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/41786/pdg-logo-process.webp 628w", "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/d4599/pdg-logo-process.webp 1256w", "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/72bc3/pdg-logo-process.webp 1884w", "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/51747/pdg-logo-process.webp 2512w", "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/4e71a/pdg-logo-process.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e4d2cc04b0a14f640afe1c62c4f04ec5/7a743/pdg-logo-process.jpg 314w", "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/6f105/pdg-logo-process.jpg 628w", "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/10d53/pdg-logo-process.jpg 1256w", "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/b2e4b/pdg-logo-process.jpg 1884w", "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/fdcb3/pdg-logo-process.jpg 2512w", "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/78bd4/pdg-logo-process.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e4d2cc04b0a14f640afe1c62c4f04ec5/10d53/pdg-logo-process.jpg",
            "alt": "Logo Design Process - UX Design | Property Deal Genie",
            "title": "Logo Design Process - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB2yMqZkf/xAAYEAEBAAMAAAAAAAAAAAAAAAABABAiMf/aAAgBAQABBQKdbuEFv//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAAQARExIf/aAAgBAQAGPwLTsrC6X//EABkQAQEBAAMAAAAAAAAAAAAAABEBABAhYf/aAAgBAQABPyFiB1T0aWQysxFGVu3H/9oADAMBAAIAAwAAABAID//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABgRAAIDAAAAAAAAAAAAAAAAAAABESEx/9oACAECAQE/EKgen//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExcUFR/9oACAEBAAE/ENoFLpcwsUyoNrwhkyaSAZh+u5XM6OWnpOYPA8n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/963ab8b36bff2740d1b931752b0c1eac/d2432/pdg-logo-final.webp 314w", "/static/963ab8b36bff2740d1b931752b0c1eac/41786/pdg-logo-final.webp 628w", "/static/963ab8b36bff2740d1b931752b0c1eac/d4599/pdg-logo-final.webp 1256w", "/static/963ab8b36bff2740d1b931752b0c1eac/72bc3/pdg-logo-final.webp 1884w", "/static/963ab8b36bff2740d1b931752b0c1eac/51747/pdg-logo-final.webp 2512w", "/static/963ab8b36bff2740d1b931752b0c1eac/4e71a/pdg-logo-final.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/963ab8b36bff2740d1b931752b0c1eac/7a743/pdg-logo-final.jpg 314w", "/static/963ab8b36bff2740d1b931752b0c1eac/6f105/pdg-logo-final.jpg 628w", "/static/963ab8b36bff2740d1b931752b0c1eac/10d53/pdg-logo-final.jpg 1256w", "/static/963ab8b36bff2740d1b931752b0c1eac/b2e4b/pdg-logo-final.jpg 1884w", "/static/963ab8b36bff2740d1b931752b0c1eac/fdcb3/pdg-logo-final.jpg 2512w", "/static/963ab8b36bff2740d1b931752b0c1eac/78bd4/pdg-logo-final.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/963ab8b36bff2740d1b931752b0c1eac/10d53/pdg-logo-final.jpg",
            "alt": "Final Logo Design - UX Design | Property Deal Genie",
            "title": "Final Logo Design - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHci6AP/8QAGBAAAgMAAAAAAAAAAAAAAAAAAAIREiD/2gAIAQEAAQUCZqk5/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAACAhQf/aAAgBAQAGPwKm1f/EABgQAQADAQAAAAAAAAAAAAAAABEAARCB/9oACAEBAAE/IaKK5DRgO//aAAwDAQACAAMAAAAQT/8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAQEAAgMBAAAAAAAAAAAAAREAITFhgZGh/9oACAEBAAE/EA5IWcLPOFlVAlOrm3iZAjfun51zvbv/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1742fea6d220c8908f7082f3db3e26f5/d2432/pdg-typo.webp 314w", "/static/1742fea6d220c8908f7082f3db3e26f5/41786/pdg-typo.webp 628w", "/static/1742fea6d220c8908f7082f3db3e26f5/d4599/pdg-typo.webp 1256w", "/static/1742fea6d220c8908f7082f3db3e26f5/72bc3/pdg-typo.webp 1884w", "/static/1742fea6d220c8908f7082f3db3e26f5/51747/pdg-typo.webp 2512w", "/static/1742fea6d220c8908f7082f3db3e26f5/4e71a/pdg-typo.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1742fea6d220c8908f7082f3db3e26f5/7a743/pdg-typo.jpg 314w", "/static/1742fea6d220c8908f7082f3db3e26f5/6f105/pdg-typo.jpg 628w", "/static/1742fea6d220c8908f7082f3db3e26f5/10d53/pdg-typo.jpg 1256w", "/static/1742fea6d220c8908f7082f3db3e26f5/b2e4b/pdg-typo.jpg 1884w", "/static/1742fea6d220c8908f7082f3db3e26f5/fdcb3/pdg-typo.jpg 2512w", "/static/1742fea6d220c8908f7082f3db3e26f5/78bd4/pdg-typo.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/1742fea6d220c8908f7082f3db3e26f5/10d53/pdg-typo.jpg",
            "alt": "Typography - UX Design | Property Deal Genie",
            "title": "Typography - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAEDBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHtQpNYP//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhIDEf/aAAgBAQABBQKn4LQi40p40hH/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAAAAAAAAAAAAQAAECAxEhMf/aAAgBAQAGPwI9wt2Scf/EABkQAAMBAQEAAAAAAAAAAAAAAAABESExQf/aAAgBAQABPyF/PrgzK1Q4MqtWpjHBtSokR//aAAwDAQACAAMAAAAQM8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAxIVFBscH/2gAIAQEAAT8QnbMFYHC/MKNF4rfWVtvElUQiTLA5ZgDp7zgEz//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e8d1e2aafcb1cb4d299657de7efe88ec/d2432/pdg-hifi-1.webp 314w", "/static/e8d1e2aafcb1cb4d299657de7efe88ec/41786/pdg-hifi-1.webp 628w", "/static/e8d1e2aafcb1cb4d299657de7efe88ec/d4599/pdg-hifi-1.webp 1256w", "/static/e8d1e2aafcb1cb4d299657de7efe88ec/72bc3/pdg-hifi-1.webp 1884w", "/static/e8d1e2aafcb1cb4d299657de7efe88ec/51747/pdg-hifi-1.webp 2512w", "/static/e8d1e2aafcb1cb4d299657de7efe88ec/4e71a/pdg-hifi-1.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e8d1e2aafcb1cb4d299657de7efe88ec/7a743/pdg-hifi-1.jpg 314w", "/static/e8d1e2aafcb1cb4d299657de7efe88ec/6f105/pdg-hifi-1.jpg 628w", "/static/e8d1e2aafcb1cb4d299657de7efe88ec/10d53/pdg-hifi-1.jpg 1256w", "/static/e8d1e2aafcb1cb4d299657de7efe88ec/b2e4b/pdg-hifi-1.jpg 1884w", "/static/e8d1e2aafcb1cb4d299657de7efe88ec/fdcb3/pdg-hifi-1.jpg 2512w", "/static/e8d1e2aafcb1cb4d299657de7efe88ec/78bd4/pdg-hifi-1.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e8d1e2aafcb1cb4d299657de7efe88ec/10d53/pdg-hifi-1.jpg",
            "alt": "Interactive Prototype - UX Design | Property Deal Genie",
            "title": "Interactive Prototype - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHtWyNAf//EABoQAAICAwAAAAAAAAAAAAAAAAABAhARITH/2gAIAQEAAQUCchcGsm6//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAECBh/9oACAEBAAY/Akg//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERITFh/9oACAEBAAE/IZOIp3SskgkuZ6Fw/9oADAMBAAIAAwAAABCk/wD/xAAWEQADAAAAAAAAAAAAAAAAAAAQESH/2gAIAQMBAT8QrH//xAAWEQEBAQAAAAAAAAAAAAAAAAARARD/2gAIAQIBAT8QYZ//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhQTFhcf/aAAgBAQABPxBKSjbWLcl77IIXmxFFC9djYR0678mh5zJ//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e021168c66f134b514031bf2d6285d1c/d2432/pdg-hifi-2.webp 314w", "/static/e021168c66f134b514031bf2d6285d1c/41786/pdg-hifi-2.webp 628w", "/static/e021168c66f134b514031bf2d6285d1c/d4599/pdg-hifi-2.webp 1256w", "/static/e021168c66f134b514031bf2d6285d1c/72bc3/pdg-hifi-2.webp 1884w", "/static/e021168c66f134b514031bf2d6285d1c/51747/pdg-hifi-2.webp 2512w", "/static/e021168c66f134b514031bf2d6285d1c/4e71a/pdg-hifi-2.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e021168c66f134b514031bf2d6285d1c/7a743/pdg-hifi-2.jpg 314w", "/static/e021168c66f134b514031bf2d6285d1c/6f105/pdg-hifi-2.jpg 628w", "/static/e021168c66f134b514031bf2d6285d1c/10d53/pdg-hifi-2.jpg 1256w", "/static/e021168c66f134b514031bf2d6285d1c/b2e4b/pdg-hifi-2.jpg 1884w", "/static/e021168c66f134b514031bf2d6285d1c/fdcb3/pdg-hifi-2.jpg 2512w", "/static/e021168c66f134b514031bf2d6285d1c/78bd4/pdg-hifi-2.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e021168c66f134b514031bf2d6285d1c/10d53/pdg-hifi-2.jpg",
            "alt": "High Fidelity Prototype - UX Design | Property Deal Genie",
            "title": "High Fidelity Prototype - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB7yDklTUP/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQMAAgQREiH/2gAIAQEAAQUCmQ8pi7drNdkDyf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAACAREgIVH/2gAIAQEABj8CI1dit3D/xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAhMUH/2gAIAQEAAT8hfu4E9vXOiRFmag6MIOCG/9oADAMBAAIAAwAAABBDD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAQADAQEBAAAAAAAAAAAAAAEAEWEhMdH/2gAIAQEAAT8QSeDhF9xRVV9hsS0G6mYHGVizuyoADCf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2d29b1e5ed171550f9e929bc51dbd477/d2432/pdg-hifi-3.webp 314w", "/static/2d29b1e5ed171550f9e929bc51dbd477/41786/pdg-hifi-3.webp 628w", "/static/2d29b1e5ed171550f9e929bc51dbd477/d4599/pdg-hifi-3.webp 1256w", "/static/2d29b1e5ed171550f9e929bc51dbd477/72bc3/pdg-hifi-3.webp 1884w", "/static/2d29b1e5ed171550f9e929bc51dbd477/51747/pdg-hifi-3.webp 2512w", "/static/2d29b1e5ed171550f9e929bc51dbd477/4e71a/pdg-hifi-3.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2d29b1e5ed171550f9e929bc51dbd477/7a743/pdg-hifi-3.jpg 314w", "/static/2d29b1e5ed171550f9e929bc51dbd477/6f105/pdg-hifi-3.jpg 628w", "/static/2d29b1e5ed171550f9e929bc51dbd477/10d53/pdg-hifi-3.jpg 1256w", "/static/2d29b1e5ed171550f9e929bc51dbd477/b2e4b/pdg-hifi-3.jpg 1884w", "/static/2d29b1e5ed171550f9e929bc51dbd477/fdcb3/pdg-hifi-3.jpg 2512w", "/static/2d29b1e5ed171550f9e929bc51dbd477/78bd4/pdg-hifi-3.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/2d29b1e5ed171550f9e929bc51dbd477/10d53/pdg-hifi-3.jpg",
            "alt": "Mockups - UX Design | Property Deal Genie",
            "title": "Mockups - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdmqBDD/xAAXEAEAAwAAAAAAAAAAAAAAAAABAhEg/9oACAEBAAEFAmQN5//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAAMAAAAAAAAAAAAAAAAAAAEgIf/aAAgBAQAGPwKlv//EABoQAAIDAQEAAAAAAAAAAAAAAAARASFxEFH/2gAIAQEAAT8hT7E0TD2jDO//2gAMAwEAAgADAAAAEBcP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAQACAgMAAAAAAAAAAAAAAREAITFBUbHB/9oACAEBAAE/EEzSFuhZ7w4VWWnE5+ZqFUmsanC+XBQBet47dZ//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e77b3b168e719f8be7e18f3412fc1e04/d2432/pdg-handoff.webp 314w", "/static/e77b3b168e719f8be7e18f3412fc1e04/41786/pdg-handoff.webp 628w", "/static/e77b3b168e719f8be7e18f3412fc1e04/d4599/pdg-handoff.webp 1256w", "/static/e77b3b168e719f8be7e18f3412fc1e04/72bc3/pdg-handoff.webp 1884w", "/static/e77b3b168e719f8be7e18f3412fc1e04/51747/pdg-handoff.webp 2512w", "/static/e77b3b168e719f8be7e18f3412fc1e04/4e71a/pdg-handoff.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e77b3b168e719f8be7e18f3412fc1e04/7a743/pdg-handoff.jpg 314w", "/static/e77b3b168e719f8be7e18f3412fc1e04/6f105/pdg-handoff.jpg 628w", "/static/e77b3b168e719f8be7e18f3412fc1e04/10d53/pdg-handoff.jpg 1256w", "/static/e77b3b168e719f8be7e18f3412fc1e04/b2e4b/pdg-handoff.jpg 1884w", "/static/e77b3b168e719f8be7e18f3412fc1e04/fdcb3/pdg-handoff.jpg 2512w", "/static/e77b3b168e719f8be7e18f3412fc1e04/78bd4/pdg-handoff.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e77b3b168e719f8be7e18f3412fc1e04/10d53/pdg-handoff.jpg",
            "alt": "Web Design - UX Design | Property Deal Genie",
            "title": "Web Design - UX Design | Property Deal Genie",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      